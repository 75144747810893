import { Form, Row, Input, Button, message, Select, InputNumber } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import Otpvalidation from "./OtpValidation.model";
import { Radio } from "antd";
import {NavLink} from 'react-router-dom';
import {Layout, Menu, Dropdown} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import desiaadmi from '../images/ludo/desiaadmi.png'

// import {environment} from './Environment';
// import logo from '../src/images/logo.jpg';
import Icon from '@ant-design/icons';
import Hamburger from 'hamburger-react';
// import {useState} from 'react';
import manue from '../static/media/menu.png'
import imglogo from "../static/media/Realludokinglogo.png"
import wollet from "../static/media/wallet.png"

import ForgotPasswordModel from "./ForgotPassword.model";
// import { Container } from "react-bootstrap";
import { Nav2 } from "../Nav2";

import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBInput,
  MDBIcon,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import { Container } from "react-bootstrap";
import axios from "axios";

const plainOptions = ['Milk Distribution', 'Milk collection'];

const { Option } = Select;


const SignupScreen = (props) => {
  let navigate = useNavigate();
  const [RoleList, setRoleList] = useState();
  const [Role, setRole] = useState();
  const [fullname, setFullname] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [memberReferCode, setMemberReferCode] = useState();
  const [referCode, setReferCode] = useState();
  const [password, setPassword] = useState();
  const [Rpassword, setPasswordV] = useState();
  const [address, setAddress] = useState();
  const [companyname, setcompanyname] = useState();
  const [defaultRole, setDefaultRole] = useState('');
  const [defaultrole, setDefaultrole] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [Otp, setOtp] = useState(1);
  const [MOtp, setMOtp] = useState(false);
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [emailverified, setEmailVerified] = useState(false);
  const [verificationId, setVerificationId] = useState('');
  const [value2, setValue2] = useState('Apple');

  const user = useContext(UserContext);
  useEffect(() => {
    let mounted = true;
    if (mounted) pageLoad();
    return () => (mounted = false);
  }, []);

  const pageLoad = () => {

      Reload()
 
  }
  const Reload = () => {
    if (props.authToken != null) {
      localStorage.clear();
      window.location.reload(true);
    }

  }
  var existMatch = ''
  const onCheakOtp = async (e) => {
    if (e.target.value.length === 6) {
      await axiosInstance.get("/email").then((response) => {
        existMatch = response.data.data.find(x => x.email === email);
      });
      if (e.target.value === existMatch.otp) {
        setMOtp(true)
        message.success("Otp successfully");
      }
      else {
        setMOtp(false)
        message.error("Wrong");

      }
    }
  };

  const onSignin = (e) => {
    navigate(`/`, { replace: true });

  };
  const onOtp = async() => {
    if(mobile !==undefined && mobile!=='' && mobile.length ===10  ){
      
      const url = `https://cpaas.messagecentral.com/verification/v2/verification/send?countryCode=91&customerId=C-832FE5D64342432&flowType=SMS&mobileNumber=${mobile}`;
      const authToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJDLTgzMkZFNUQ2NDM0MjQzMiIsImlhdCI6MTcyMTIyMDAxNCwiZXhwIjoxODc4OTAwMDE0fQ.fT7RuX8RN2eGhGKSVjxStJLHgvrAFYNXrI7AV-1dYVE-UnOPpUr8ClooD2UN-sLAGUqOdBCUOUZvw11qSbpS6w";

      try {
        const response = await axios.post(url, {}, {
          headers: {
            'authToken': authToken
          }
        });
        console.log(response.data.data);
        if (response.data.responseCode ==200 ) {
          setVerificationId(response.data.data.verificationId)
          message.success("Otp Sent successfully");
         setIsModalOpen(true);
        setMOtp(false)
     
   } else if (response.data.responseCode ==506) {
    message.error("after one minute you can send otp again ...!");
   } else message.error("Something wrong. Please try again...!");
      } catch (error) {
        console.error('Error:', error);
      }
     
   
  }
else {
  message.error("please enter valid mobile number...!")
}
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };
  
  const onSignup = () => {
    if (MOtp === true) {
      const data = {
        email: email,
        name: fullname,
        mobile: mobile,
        password: password,
        referCode: referCode,
        registerDate:new Date()
      };
       if(data.name!==undefined&& data.password!==undefined&& data.email!==undefined&& data.name!==''&& data.password!==''&& data.email!==''){
        axiosInstance.post("/member", data).then((res) => {
          if (res.data && res.data.responseCode === -1) {
            message.error("Record Already Exists");
          } else if (res.data && res.data.responseCode === 1) {
            message.success("Record saved successfully");
            navigate(`/LoginScreen`, { replace: true });
          } else message.error("Something wrong. Please try again...!");
        });
     }
     else{
      message.error("Please fill out all required fields. And submit again...!");
     }
    }
    else {
      message.error("Plese Varify Your Otp");
    }
  };
  const onEmailChange = (input) => {
    setEmail(input.target.value)
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-].+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.target.value.match(validRegex)) {

      //alert("Valid email address!");

      // document.form1.text1.focus();

      setEmailVerified(true);

    }
    else {

      // alert("Invalid email address!");

      document.form1.text1.focus();

      setEmailVerified(false);

    }

  }

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
  <div className="realludokingsize bgclr">
      <div style={{marginTop:'15%',marginBottom:'100%'}}>
      <div className="d-flex align-items-center justify-content-between">
                  <div style={{width:"20%"}}>
                    <div className="">
                      {/* <img src={refer} style={{width:"80%"}} alt="" /> */}
                    </div>
                    <div className="">
                      {/* <img src={Support} className="mt-3" style={{width:"80%"}} alt="" /> */}
                    </div>
                  </div>
                  <div style={{width:"60%"}}>
                    <img className="mainaadmi" src={desiaadmi} style={{width:'100%'}} alt="" />
                  </div>
                  <div style={{width:"20%",textAlign:'end'}}>
                    <div className="">
                      {/* <img src={qa} style={{width:"80%"}} alt="" /> */}
                    </div>
                    <div className="">
                      {/* <img src={member} className="mt-3" style={{width:"80%"}} alt="" /> */}
                    </div>
                  </div>
                  
                  
                </div>
      <div className=' p-agggg' style={{marginTop:'-10px'}}>
              <div className="my-2 card02 ">
              <div class="col mx-auto g-0 iframe-sec p-3"><div class=""><div class=" text-white card-header text-center">Sign-Up</div>
<div className=" gradient-form ">

<div>

  <div className="card-body">
   
  <div>

{/* <p classNAme="text-center h1 fw-bold mb-5 text-white mx-1 mx-md-4 mt-4 w-100 mx-auto">Sign up</p> */}

<div className="d-flex flex-row align-items-center mb-4 ">
 
  <MDBInput placeholder='Your Name' id='form1' onChange={(e)=>setFullname(e.target.value)} type='text'/>
</div>

<div className="d-flex  align-items-center mb-4">

  <MDBInput placeholder='Your Email' onChange={(e)=>setEmail(e.target.value)} id='form2' type='email'/>
</div>
<div className="d-flex flex-row align-items-center mb-4">
 
  <MDBInput placeholder='Your Mobile Number' onChange={(e)=>setMobile(e.target.value)} id='form2' type='number'/>
  {(MOtp === false) &&  <Button className=" raj033333 btn btn-primary border-0" onClick={() => onOtp()}>
                        Gen. Otp
                      </Button>}
                   {(MOtp === true) && <div style={{ width: "40%" }}>
                      <Button className="btnmy4" >
                      <svg className="veryfied" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg> Veryfied
                      </Button>
                    </div>}
</div>
<div
                    style={{ alignItems: "flex-end", marginLeft: "65%", marginBottom: "-7%", }}
                  >

                    <Otpvalidation
                       isVisible={isModelOpen}
                       onCancel={onCancel}
                       onAdd={onAdd}
                       onCheakOtp={onCheakOtp}
                       mobile={mobile}
                       verificationId={verificationId}
                       setMOtp={setMOtp}
                       setIsModalOpen={setIsModalOpen}
                       setOtp={setOtp}
                    />

                  </div>
<div className="d-flex flex-row align-items-center mb-4">
  <div fas icon="lock me-3" size='lg'/>
  
</div>

<div className="d-flex flex-row align-items-center mb-4">
  <div fas icon="key me-3" size='lg'/>
  <MDBInput placeholder='Referal Code' onChange={(e)=>setReferCode(e.target.value)} id='form4' type='text'/>
</div>
<div className="d-flex flex-row align-items-center mb-4">
  <div fas icon="key me-3" size='lg'/>
  <MDBInput placeholder='password' onChange={(e)=>setPassword(e.target.value)} id='form4' type='password'/>
</div>

{/* <div className='mb-4'>
  <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Subscribe to our newsletter' />
</div> */}

<div className="text-center">
<button className='mb-4 btn btn-primary text-center' size='lg' onClick={() =>onSignup()}>Register</button>

</div>
</div>
  </div>



</div>

</div>
  </div></div>
              </div>
              </div>
  



</div>



  </div>
  );
};

export default SignupScreen;

// 