import React, { useContext, useEffect, useRef, useState } from "react";

import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";

import ludo from '../images/ludo/classic-fixed.png'
import women from '../images/ludo/woman.png'
import walletman from '../images/ludo/invant.png'
import rewards from '../images/ludo/rewardlast.png'
import hestoryicon from '../images/ludo/joinvs.png'
import notificatrion from '../images/ludo/new-message.png'




import call from "../static/media/live-chat.png";
import home from '../images/ludo/kkkkk.png'
import profile02 from '../images/ludo/prfils.png'

import hello from "../static/media/4.png"
import axiosInstance from "../axiosInstance";
import ludo02 from "../images/ludo/classic-modemanual.png"
import ludo03 from "../images/ludo/quick-mode.png"

import axios from "axios";

import box01 from '../images/ludo/box01.png'
import walletmain from '../images/ludo/walletmain.png'
import plus from '../images/ludo/plus.png'
import desiaadmi from '../images/ludo/desiaadmi.png'
import refer from '../images/ludo/refer01.png'
import Support from '../images/ludo/support.png';
import qa from '../images/ludo/qa.png';
import member from '../images/ludo/member.png';
import wifi65 from '../images/ludo/system-solid-102-wifi.gif';
import statusvideo from '../images/ludo/status_1690766541785.mp4'
import click from '../static/media/music/click.mp3'
import av01 from '../../src/images/avtar/av01.svg'

import av02 from '../../src/images/avtar/av02.svg'
import av03 from '../../src/images/avtar/av03.svg'
import av04 from '../../src/images/avtar/av04.svg'
import av05 from '../../src/images/avtar/av05.svg'
import av06 from '../../src/images/avtar/av06.svg'
import av07 from '../../src/images/avtar/av07.svg'
import av08 from '../../src/images/avtar/av08.svg'
import av09 from '../../src/images/avtar/av09.svg'
import av10 from '../../src/images/avtar/av10.svg'
import av11 from '../../src/images/avtar/av11.svg'
import av12 from '../../src/images/avtar/av12.svg'

















const styles = {
  button023: {


  },

};




const HomeScreen = (props) => {
  const user = useContext(UserContext);
  let navigate = useNavigate();

  const [isPlaying, setIsPlaying] = useState(false);
  const [walletAmount, setWalletAmount] = useState();

  const audioRef = useRef(null);
  // Reference to the video element
  const videoRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const handleClick02 = () => {
    setIsActive(!isActive); // Toggle the state
  }
  // Function to handle play/pause button click
  const handleTogglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId === null) {
        localStorage.clear();
        navigate('/LoginScreen', { replace: true });
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWallet();
  }
  var count = 1;
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
      if (res.data.data === null) {
        count = count + 1;
        if (count < 5) {
          getWallet();
        }
        if (count === 5) {
          const data1 = {
            member: user.userId,
            amount: 20,
            winningAmount: 0,
            depositeAmount: 20,
            bonus: 0,
            winningFreezAmount: 0,
            totalWinningAmount: 0,
            RefralWinningAmount: 0
          }
          axiosInstance.post("/wallet", data1).then((res) => {
          });
        }
      }
      else {
        setWalletAmount(
          res.data.data.winningAmount + res.data.data.depositeAmount,
        );
      }
    });
  }


  const onRedirect = (e) => {
    audioRef.current.play();
    navigate(`/${e}`)
  };
  const onStatus = () => {
    let vid = document.getElementById("myVideo");
    vid.onpause();
  }




  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;
    const currentX = e.touches[0].clientX;
    const difference = startX - currentX;
    if (Math.abs(difference) > 50) {
      if (difference > 0) {
        navigate('/Wallet')
        console.log('Swipe left');
      } else {
        navigate('/Profile')
        console.log('Swipe right');
      }
      setStartX(null);
    }
  };





  return (

    <div className=""
     onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={() => setStartX(null)}
      style={{ width: '100%', height: '100vh', backgroundColor: '#f0f0f0', }}>
      <div className="addfi">
        <div className="japurLudoNav realludokingsize bgclr " >
          <div className=" bgclr02">
            <div class="mainheader">
              <div className="p-2">
                <div className="d-flex align-items-center justify-content-between mt-1">
                  <div className="mainboxitem" style={{ position: 'relative' }}  >
                    <img src={box01} alt="" className="mainbox" />
                    <img src={av02} style={{ position: 'absolute' }} alt="profile-pic main" class="profile-pic main" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" />

                  </div>
                  <div className="mainboxitem" style={{ position: 'relative' }}>
                    <img src={box01}  alt="" className="mainbox" />
                    <img src={notificatrion} style={{width:'70%',position:'absolute',top:'8px',textAlign:"center", left:'7px'}}  alt=""  />

                    </div>
                </div>
                <div className="d-flex justify-content-center mb-2">
                  <button onClick={() => onRedirect("wallet")} className="d-flex  align-items-center headerbutton  btn btn-light"><img src={walletmain} className="mr-5" alt="" width={30} /> &#8377;{walletAmount}<img src={plus} style={{ paddingLeft: '10px', marginRight: '2px', marginTop: '1px' }} alt="" width={26} /></button>
                </div>
              </div>
            </div>
            <div className="p-3 pt-0">
              <div className="d-flex align-items-center justify-content-between">
                <div style={{ width: "20%" }}>
                  <div className="" onClick={() => onRedirect("Refere")} >
                    <img src={refer} style={{ width: "80%" }} alt="" />
                  </div>
                  <div className="" onClick={() => onRedirect("support")}>
                    <img src={Support} className="mt-3" style={{ width: "80%" }} alt="" />
                  </div>
                </div>
                <div style={{ width: "60%" }}>
                  <img className="mainaadmi" src={desiaadmi} style={{ width: '100%', position: 'relative' }} alt="" />
                  <div class="planet-wrapper" style={{ position: 'relative' }}></div>
                </div>
                <div style={{ width: "20%", textAlign: 'end' }}>
                  <div className="" onClick={() => onRedirect("Terms")}>
                    <img src={qa} style={{ width: "80%" }} alt="" />
                  </div>
                  <div className="">
                    <img src={member} className="mt-3" style={{ width: "80%" }} alt="" />
                  </div>
                </div>


              </div>
              <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner " style={{ marginTop: '-2px' }}>
                  <div class="carousel-item active">
                    <div className="alert02">
                      <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
                        <div className=" w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>kamal </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                        <div className="w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>Rohit </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className="alert02">
                      <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
                        <div className=" w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>kamal </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                        <div className="w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>Kamal </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className="alert02">
                      <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
                        <div className=" w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>kamal </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                        <div className="w-50 bgredg d-flex m-1 p-1 align-items-center justify-content-between">
                          <div>
                            <img src={women} className="hello" alt="" /><span>Kamal </span>
                          </div>
                          <div >
                            <h6 className="p-1" style={{ marginBottom: '0px', marginRight: '1px', background: 'yellow', borderRadius: '20px', }}><span style={{ padding: "5px", fontSize: "14px" }}>&#x20B9;155</span></h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>

              <div>


                <div className="d-flex mt-4">
                  <div className="col text-center">

                    <a className="text-decoration-none text-black" href="#/Game1">
                      <picture>
                        <source
                          media="(min-width:1024px)"
                          srcSet={ludo}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={ludo}
                        />
                        <img
                          src={ludo}
                          alt="ludo classic"
                          className="rounded-3"
                          style={{ width: '90%', marginLeft: '20px', cursor: 'pointer' }}
                        />

                      </picture>
                    </a>
                  </div>
                  <div className="col text-center">
                    <a className="text-decoration-none text-black" href="#/Game2">
                      <picture>
                        <source
                          media="(min-width:1024px)"
                          srcSet={ludo02}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={ludo02}
                        />
                        <img
                          src={ludo02}
                          alt="ludo classic"
                          className="rounded-3"
                          style={{ width: '90%', marginRight: '20px', cursor: 'pointer' }}
                        />
                      </picture>
                    </a>
                  </div>



                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="w-50 text-center table01" style={{ width: '90%', marginLeft: '20px', cursor: 'pointer' }}><span className="d-flex align-items-center justify-content-center"><img style={{ width: '18px', marginRight: '2px' }} src={wifi65} alt="" /><span style={{ marginTop: '2px' }}>Table: &#8377;50-5000</span></span></div>
                  <div className="w-50 text-center table01" style={{ width: '90%', marginRight: '20px', cursor: 'pointer' }}><span className="d-flex align-items-center justify-content-center"><img style={{ width: '18px', marginRight: '2px' }} src={wifi65} alt="" /><span style={{ marginTop: '2px' }}>Table: &#8377;501-100000</span></span></div>
                </div>
                <div className="d-flex mt-2">

                  <div className="col text-center">

                    <a className="text-decoration-none text-black" href="javascript:void(0)">
                      <picture>
                        <source
                          media="(min-width:1024px)"
                          srcSet={ludo03}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={ludo03}
                        />
                        <img
                          src={ludo03}
                          alt="ludo classic"
                          className="rounded-3"
                          style={{ width: '90%', marginLeft: '20px', cursor: 'pointer' }}
                        />
                      </picture>
                    </a>



                  </div>
                  <div className="col text-center">
                    <a className="text-decoration-none text-black" href="javascript:void(0)">
                      <picture>
                        <source
                          media="(min-width:1024px)"
                          srcSet={ludo03}
                        />
                        <source
                          media="(min-width:768px)"
                          srcSet={ludo03}
                        />
                        <img
                          src={ludo03}
                          alt="ludo classic"
                          className="rounded-3"
                          style={{ width: '90%', marginRight: '20px', cursor: 'pointer' }}
                        />
                      </picture>
                    </a>
                  </div>
                </div>


                {/* <div className="text-center">
            <img src={leader} style={{width:'60%'}} alt="" />
          </div> */}
                <div className="d-flex align-items-center justify-content-between pt-0 pb-5 p-3 mt-4" style={{marginBottom:'100%'}}>
                  <div className="col-md-3">
                    <div class="circle02" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">
                      <img src="https://en.gravatar.com/userimage/8283692/4c9d9ec1cd3fd02acb5ac9572e3583da?size=200" className="circleimg" alt="" />
                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                        space="preserve">
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="circle02" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={handleTogglePlay} data-bs-whatever="@mdo">
                      <img src="https://en.gravatar.com/userimage/8283692/4c9d9ec1cd3fd02acb5ac9572e3583da?size=200" className="circleimg" alt="" />
                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                        space="preserve">
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="circle02">
                      <img src="https://en.gravatar.com/userimage/8283692/4c9d9ec1cd3fd02acb5ac9572e3583da?size=200" className="circleimg" alt="" />
                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                        space="preserve">
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div class="circle02">
                      <img src="https://en.gravatar.com/userimage/8283692/4c9d9ec1cd3fd02acb5ac9572e3583da?size=200" className="circleimg" alt="" />
                      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
                        space="preserve">
                        <circle cx="50" cy="50" r="40" />
                      </svg>
                    </div>
                  </div>

                </div>
                <div className="mt-3">

                </div>







              </div>


              <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                <div class="offcanvas-header">
                  <div class="offcanvas-title h5" id="offcanvasBottomLabel">How To Play Games &amp; Earn?</div>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small">
                  Your Video
                </div>
              </div>
              <div className="homeScreenL">
                <div className=" col-12 mx-auto g-0 iframe-sec p-3">
                  {/* <div role="alert" class="fade d-flex align-items-center justify-content-between alert alert-danger show">
        <span><b>KYC Pending <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20"
                    fill="red">
                    <path
                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
                    </path>
                </svg></b></span><a href="#/kyc"><button class="btn btn-danger btn-sm text-capitalize">complete
                here</button></a></div> */}


                  {/* <div className="alert02">
            <div className="text-center mainwelcome" ><img src={hello} className="hello" alt="" />welcome IN Real Ludo King</div>
          </div> */}

                  {/* <div class="d-flex align-items-center justify-content-between mt-3 mb-4">
            <h1 class="text-capitalize text-start mainh1
        ">games</h1><button type="button"
              class="d-flex align-items-center btn btn-outline-primary btn-md" ><svg 
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor"
                class="me-1">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                <path
                  d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                </path>
              </svg><span class="text-capitalize" >guide</span></button>

          </div> */}


                  {/* <a class="bg-light border shadow rounded-circle d-flex align-items-center justify-content-center position-fixed text-dark meniicon" href="#/spinNwin" ><img src={spin} height="36px" alt="spin wheel icon"/></a> */}
                  {/* <a class="bg-light border shadow rounded-circle d-flex align-items-center justify-content-center position-fixed text-dark 23" href="#/support"><img src={call} height="36px" alt="spin wheel icon" /></a> */}


                </div>
              </div>
            </div>


          </div>




          <div class="offcanvas offcanvas-top myadding" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
            <div class="offcanvas-header" style={{ background: '#000' }}>
              <h5 id="offcanvasTopLabel" style={{ color: '#fff' }}>Select Avatar</h5>
              <button type="button" class="btn-close text-reset me-2" data-bs-dismiss="offcanvas" aria-label="Close">&#10006;</button>
            </div>
            <div class="offcanvas-body" style={{ background: '#000' }}>
              <div class="pt-3 pb-2">
                <div class="my-2 card02">

                  <div class="card-body">
                    <div class="row row-cols-4 g-2">


                      <div class="col"><img src={av01} width="60" alt="" onClick={handleClick02} className={isActive ? 'active' : 'border border-primary border-4 rounded-circle'} /></div>
                      <div class="col"><img src={av02} width="60" alt="" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av03} width="60" alt="" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av04} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av05} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av06} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av07} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av08} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av09} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av10} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av11} alt="" width="60" class="border border-white rounded-circle" /></div>
                      <div class="col"><img src={av12} alt="" width="60" class="border border-white rounded-circle" /></div>
                    </div>
                    <div class="d-grid mb-2  mt-4"><button type="button" class="text-capitalize btn btn-primary" >save</button></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-container">
    <audio ref={audioRef} src={click} />
      <button onClick={()=>onRedirect("wallet")}  class="button02 addleft">
      <img style={{width:"45px"}}  className="home01"  src={rewards} alt="" />
     
      </button>
   
      <button onClick={()=>onRedirect("profile")} style={styles.button023} class="button02" >
      <img style={{width:"36px"}} className="home01"   src={profile02} alt="" />
      </button>
      <button onClick={()=>onRedirect("homescreen")}  class="button02">
      <img style={{width:"45px"}} className="home01 homeeee"  src={home} alt="" />
      </button>
      <button onClick={()=>onRedirect("wallet")}  class="button02">
      <img style={{width:"40px"}}  src={walletman} className="home01" alt="" />
      </button>
      <button onClick={()=>onRedirect("history")}  class="button02 addright">
      <img style={{width:"45px"}}  className="home01"  src={hestoryicon} alt="" />

      </button>
    </div>





        </div>
      </div>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Story 1</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <video ref={videoRef} playsinline="playsinline" controls muted="" preload="no" onClick={() => onStatus()} id="myVideo" class="video-js" data-setup='{"autoplay":"any"}'>
                <source
                  src={statusvideo}
                  type="video/mp4" />
              </video>
              <div onClick={handleTogglePlay}>
                {isPlaying ? 'Pause' : ''}
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" onClick={handleTogglePlay} class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {/* <button type="button" class="btn btn-primary">Send message</button> */}
            </div>
          </div>
        </div>
      </div>


      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel2">Story 2</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
              <video playsinline="playsinline" controls muted="" preload="no" loop="loop" id="myVideo" class="video-js" data-setup='{"autoplay":"any"}'>
                <source
                  src={statusvideo}
                  type="video/mp4" />
              </video>
            </div>
            <div class="modal-footer">
              <button type="button" onclick="pauseVid()" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {/* <button type="button" class="btn btn-primary">Send message</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;