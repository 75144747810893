import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Row, Input, Button, message, Modal, Image } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, EyeTwoTone } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import { MDBInput } from 'mdb-react-ui-kit';
import manue from '../static/media/menu.png'
import imglogo from "../static/media/hemadesiludo.png"
import wollet from "../static/media/wallet.png"
import ForgotPasswordModel from "./ForgotPassword.model";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import desiaadmi from '../images/ludo/desiaadmi.png'

const LoginScreen = () => {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId !== null) {
        // navigate("/HomeScreen", { replace: true });
        localStorage.clear();
        window.location.reload();
      }


    }
    return () => (mounted = false);
  }, []);

  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };

  const onSignin = () => {


    axiosInstance
      .post("/login/mobile", { mobile: mobile, password: password })
      .then((res) => {

        if (res.data) {
          if (res.data.responseCode === 1) {

                        localStorage.setItem("authtoken", JSON.stringify(res.data.auth));
             //navigate(`/HomeScreen`, { replace: true });
                       window.location.href = "/";
            toast.info('Success message', {
              position: "top-left",
              autoClose: 2000, // Close the toast after 2 seconds
            });
          }
          if (res.data.responseCode === -2) {
            message.error("Your account is temporarily frozen");
          }
          if (res.data.responseCode === -1) {
            message.error("please enter correct mobile number or password");
          }

        } else message.error("please enter correct mobile number or password");
      })
      .catch((err) => {
        console.log("hsagdjshdjka", err)
        message.error("please enter correct mobile number or password", err);
      });
  };
  const onSignup = () => {
    navigate('/SignupScreen', { replace: true });
    // window.location.href= `/SignupScreen`;
  };

  const onForgotPassword = () => {
    setIsModalOpen(true);
    // navigate('/SSignupScreen', { replace: true });

  };


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const onshowPassword = () => {
    setShowPassword(!showPassword);
  };


  return (

    <div className="realludokingsize bgclr" >
    





<div style={{marginTop:'15%',marginBottom:'100%'}}>
<div className="d-flex align-items-center justify-content-between">
                  <div style={{width:"20%"}}>
                    <div className="">
                      {/* <img src={refer} style={{width:"80%"}} alt="" /> */}
                    </div>
                    <div className="">
                      {/* <img src={Support} className="mt-3" style={{width:"80%"}} alt="" /> */}
                    </div>
                  </div>
                  <div style={{width:"60%"}}>
                    <img className="mainaadmi" src={desiaadmi} style={{width:'100%'}} alt="" />
                  </div>
                  <div style={{width:"20%",textAlign:'end'}}>
                    <div className="">
                      {/* <img src={qa} style={{width:"80%"}} alt="" /> */}
                    </div>
                    <div className="">
                      {/* <img src={member} className="mt-3" style={{width:"80%"}} alt="" /> */}
                    </div>
                  </div>
                  
                  
                </div>
<div className=' p-agggg ' style={{marginTop:'-10px'}}>
              <div className="my-2 card02 ">
              <div class="col mx-auto g-0 iframe-sec p-3 "><div class="">
        <div className=" gradient-form ">

          <div>

            <div className="card-body pt-0">
              <div className="d-flex flex-column text-white text-center">



                <p className="buttonft02 text-white">Please login to your account</p>


                <MDBInput wrapperClass='mb-4' maxLength={10} placeholder='Mobile Number' onChange={(e) => setMobile(e.target.value)} id='form1' type='number' />
                <MDBInput wrapperClass='mb-4' placeholder='Password' onChange={(e) => setPassword(e.target.value)} id='form2' type={showPassword ? 'text' : 'password'}>
                  <EyeOutlined className="showPassword 02" onClick={() => onshowPassword()} />
                </MDBInput>


                <div className="text-center pt-1 mb-2 pb-1">
                  <div >
                    <button className="mb-4 w-100 gradient-custom-2 btn btn-primary" onClick={onSignin}>Sign in</button>
                  </div>
                  <span onClick={() => onForgotPassword()} style={{ color: "#18aeff", textDecoration: "underline", }}>  Forgot password?</span>


                </div>

                <div className="d-flex flex-row align-items-center justify-content-center pb-4 ">
                  <p className="mb-0 text-white">Don't have an account?</p>
                  <div className="lcreate ms-3">
                    <a href="#/SignupScreen "style={{textDecoration:'underline'}}>Create account...</a>

                  </div>
                </div>

              </div>

            </div>

            <div >
              {isModalOpen && <ForgotPasswordModel
                isVisible={isModalOpen}
                onCancel={onCancel}
                onAdd={onAdd}
                setIsModalOpen={setIsModalOpen}
              />}
            </div>

          </div>

        </div>
      </div></div>
              </div>
              </div>


</div>
    
      <ToastContainer />

    </div>
  );
};

export default LoginScreen;