import React, { useContext, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import women from '../../images/ludo/woman.png'
import back from "../../images/ludo/back01.png"

import { message } from "antd";
import axiosInstance from "../../axiosInstance";
const Withdraw = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();

const [upi , setUpi] = useState()
const [upiR , setUpiR] = useState()
const [payment , setPayment] = useState()
const [walletAmount , setWalletAmount] = useState()
const [wwalletAmount , setWWalletAmount] = useState(null)

  useEffect(() => {
    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPayment();
  }
  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then((response) => {
      
    setWalletAmount(response.data.data.winningAmount+response.data.data.depositeAmount);
    setWWalletAmount(response.data.data.winningAmount)
    });
  };

  const onWithdraw = () => {
    if(payment>99){
      if(payment<wwalletAmount){
        const data = {
          userId:user.memberId,
          member:user.userId,
          amount:payment,
          upi:upi,
          status:"requested",
          transactionDate:new Date()
        };
        console.log("hii")
      
         if(data.userId!==undefined  && data.member!==undefined&& data.amount!==undefined&& data.status!==undefined && data.upi!==undefined&& data.upi!==''&& data.status!==''&& data.amount!==''&& data.member!==''&& data.userId!==''){
          axiosInstance.post("/withdraw", data).then((res) => {
            if (res.data && res.data.responseCode === -1) {
              message.error("Record Already Exists");
            } else if (res.data && res.data.responseCode === 1) {
              console.log("hii")
              const data2 = {
                winningAmount : wwalletAmount-payment
             }
             console.log("winningAmount",data2.winningAmount)

            axiosInstance.put(`/wallet/${user.userId}` , data2).then((response) => {
                console.log(response.data.data)
              });
              message.success("Record saved successfully");
              navigate(`/Wallet`, { replace: true });
            } else message.error("Something wrong. Please try again...!");
          });
       }
       else{
        message.error("Please fill out all required fields. And submit again...!");
       }
      }
      else{
        message.error(" Insuficiant Chips, Please re enter valid Chips. And submit again...!");
        navigate(`/Wallet`, { replace: true });
      }
    }
 else{
  message.error(" You can withdraw only Rs 100 or amount above Rs 100....!");
 }
   
  };

  return (

   <div className="realludokingsize bgclr" style={{paddingBottom:'100%'}}>
 <div className=" d-flex align-items-center justify-content-between mt-3 px-3 addped">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
       
      </div>
     <div className="col-12 mx-auto g-0 iframe-sec " style={{marginBottom:'71%'}}>
    
     <div className=' p-agggg pt-3 ms-1 me-1'>
              <div className="my-2 card02">
              <div className="">
        <div className=" text-white text-center card-header ">Payment Mode</div>
        <div className=" text-center py-1 buttonft">
          {wwalletAmount !== null && <span className="text-white text-center" style={{ fontSize: '0.8rem' }}>Withdrawal Chips: {wwalletAmount}</span>}
          {wwalletAmount === null && <span className="text-white text-center" style={{ fontSize: '0.8rem' }}>Withdrawal Chips: 0.00</span>}
        </div>
        <div className="card-body">
          <div className="d-flex text-white flex-row align-items-center justify-content-between mb-1">
            <span style={{ fontSize: '0.8rem' }}>Minimum: 100</span>
            <span style={{ fontSize: '0.8rem' }}>Maximum: 1,00,000</span>
          </div>
        </div>
      </div>
              </div>
              </div>
     
              <div className=' p-agggg ms-1 me-1'>
              <div className="my-2 card02">
              <div className="">
<div className="pt-3">
<div className=" text-white text-center buttonft02 card-header">Payment Details</div>

  </div>        <div className="card-body">
            <div className="vstack gap-3">
              <div>
                <label className="text-capitalize text-start text-white  w-100 form-label" htmlFor="upiFormAccountNumber">UPI ID</label>
                <input placeholder="Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormAccountNumber" className="form-control form-control" onChange={(e)=>{setUpi(e.target.value)}} />
              </div>
              <div>
                <label className="text-capitalize text-start text-white w-100 form-label" htmlFor="upiFormReEnterAccountNumber" >Re Enter UPI ID</label>
                <input placeholder="Re Enter Your UPI ID" pattern="^[\w.+-]+\@[\w-]+(?!\w*\.[\w]+)$" type="text" id="upiFormReEnterAccountNumber" className="form-control form-control" onChange={(e)=>{setUpiR(e.target.value)}} />
              </div>
              <div>
                <label className="text-capitalize text-start text-white w-100 form-label" htmlFor="upiFormChips">Chips</label>
                <input placeholder="Chips" min="95" max="100000" type="number" id="upiFormChips" className="form-control form-control" onChange={(e)=>{setPayment(e.target.value)}} />
              </div>
             
            </div>
            <div>
     <div className="text-center "> 
     <button  disabled="" className="btn btn-primary text-center mt-3 mb-2" onClick={()=>onWithdraw()}>Submit</button>
     </div>

            </div>
        </div>
      </div>
              </div>
              </div>
     

      <div style={{ marginBottom: '15px' }}>
        <ul className="list-group"></ul>
      </div>
    </div>
   </div>
  );
};

export default Withdraw;