import React, { useContext, useEffect, useState,useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import { message } from "antd";
import axiosInstance from "../../axiosInstance";
import women from '../../images/ludo/woman.png'
import back from "../../images/ludo/back01.png"

const Recharge = (props) => {
  const textRef = useRef(null);
const [amount , setAmount] = useState()
const [upi , setUPI] = useState()
const [upiId , setUPIId] = useState()
const [utr , setUtr] = useState()
const [utrShow , setUtrShow] = useState(true)
const [walletAmount, setWalletAmount] = useState();

  const user = useContext(UserContext);
  let navigate = useNavigate();
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
  getUpi()
  getPayment()
  }
  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then(response => {
      setWalletAmount(
        response.data.data.winningAmount + response.data.data.depositeAmount,
      );
    });
  };

  const getUpi = () => {
    axiosInstance.post("/upi/active").then((response) => {
      setUPIId(response.data.data[0]._id);
      setUPI(response.data.data[0].name);
    });
  };
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };

  const onPay = () => {
axiosInstance.get('/orderId').then((res)=>{
  const userId = res.data.data[0].orderId +1
  const data ={
    orderId:res.data.data[0].orderId +1
  } 
  axiosInstance.put(`/orderId/${res.data.data[0]._id}`,data).then((res)=>{
    onRecharge(userId)
  })


})
   };
   const onRecharge = (e) => {
    const data = {
      userId:user.memberId,
      member:user.userId,
      amount:amount,
      status:"requested",
      orderId:e,
      transactionDate:new Date()
    };
    console.log(data)
     if(data.userId!==undefined  && data.member!==undefined&& data.amount!==undefined&& data.status!==undefined && data.orderId!==undefined&& data.orderId!==''&& data.status!==''&& data.amount!==''&& data.member!==''&& data.userId!==''){
      axiosInstance.post("/payment", data).then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record saved successfully");
          onPayCheck(res.data.data)
        } else message.error("Something wrong. Please try again...!");
      });
   }
   else{
    message.error("Please fill out all required fields. And submit again...!");
   }
   
  };
  const onPayCheck = (e) => {
    console.log("onpayCheck",e)
     const data = {
      customer_mobile: user.mobile,
      user_token: "1ae0b12c53a3223c9ab631f71744bcea",
      amount: e.amount,
      order_id: e.orderId,
      redirect_url: "https://realludoking.com/#/wallet",
      remark1: "testremark",
      remark2: "testremark2"
     }
    axiosInstance.post('/roomeCode/payment',data).then((res)=>{
        console.log("payCheckurl",res.data.result.payment_url)
        window.open(res.data.result.payment_url, '_blank');
       // window.open=`${res.data.result.payment_url}`
    })
       };
  return (
<div className="realludokingsize bgclr">
<div className=" d-flex align-items-center justify-content-between mt-3 px-3 addped">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
          
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{walletAmount}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
       
      </div>
  <div className=' p-agggg ms-1 me-1' style={{marginBottom:'100%'}}>
              <div className="my-2 card02">
              <div className="p-3">
              <div className="buttonft text-center text-white mb-2">Buy Chips</div>
     
        <div className="">
          <div role="alert" className="fade d-flex align-items-center justify-content-between alert alert-warning show" style={{ fontSize: '0.7rem', textAlign: 'unset' }}>
            <span>
              <b>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="red">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </svg>
                &nbsp;&nbsp;यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID पूर्णतः बंद कर दी जाएगी !
              </b>
            </span>
          </div>
          <div className="">
            <div className="form-group">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <label htmlFor="amount" className="form-label w-100 text-white text-center">Enter Amount</label>
                <div className="input-group mb-2">
                  <span className="input-group-text bg-light text-dark">₹</span>
                  <input type="number" placeholder="Amount" id="amount" min="1" max="20000" className="form-control" onChange={(e) =>{setAmount(e.target.value)}}  />
                
                </div>


             
                <div id="whichPG" className="d-flex justify-content-evenly align-items-center mb-2 w-100">
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="cashfree" className="form-check-input" value="cashfree" />
                    <label htmlFor="cashfree" className="form-check-label">
                      <h5 className="text-capitalize">UPI</h5>
                    </label>
                  </div> */}
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="phonepe" className="form-check-input" value="phonepe" checked />
                    <label htmlFor="phonepe" className="form-check-label">
                      <h5 className="text-capitalize">UPI-2</h5>
                    </label>
                  </div> */}
                </div>
                <div>
               <button className="btn btn-primary" onClick={()=>onPay()}>Pay</button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-capitalize text-secondary text-center mt-4 mb-0">Payments secured by</p>
          <div className="d-flex justify-content-center align-items-start">
            <div className="hstack gap-2 d-flex justify-content-center align-items-start ">
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg" alt="gpay logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg" alt="paytm logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg" alt="phonepe logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg" alt="upi logo" width="48" />
            </div>
          </div>
        </div>
      </div>
              </div>
              </div>
    
    </div>
  );
};

export default Recharge;