import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from '../../globalContext';
import {useNavigate} from 'react-router-dom';
import ludo from '../../images/ludo/ludoavt.svg';
import {Input, Modal, message} from 'antd';
import axiosInstance from '../../axiosInstance';
import trophy from '../../static/media/trophy.png';
import vs from '../../images/ludo/joinvs.png';
import back from "../../images/ludo/back01.png";
import wifi02 from "../../images/ludo/system-solid-102-wifi.gif"
import women from '../../images/ludo/woman.png';
import desiaadmi from "../../../src/images/ludo/desiaadmi.png"
import prizepool from '../../images/ludo/1st-prize.png'
const Game2 = () => {
  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [amount, setAmount] = useState(null);
  const [runingGame, setRuningGame] = useState();
  const [runingTable, setRuningTable] = useState();
  const [runingTable2, setRuningTable2] = useState();
  const [runingTableStatus, setRuningTableStatus] = useState(false);
  const [runingTableStatus2, setRuningTableStatus2] = useState(false);
  const [runing, setRuning] = useState(false);
  const [walletAmount, setWalletAmount] = useState();
  const [playWait, setPlayWait] = useState(false);
  const [playWait11, setPlayWait11] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user.userId == null) {
        window.location.reload(true);
      }
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getPayment();
    getRuningGame();
    getRuningTable(0);
    getRuningTable2()
  };


  useEffect(() => {
    const interval = setInterval(() => {
      getRuningTable(0);
      getRuningTable2()
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getPayment = () => {
    axiosInstance.get(`wallet/${user.userId}`).then(response => {
      setWalletAmount(
        response.data.data.winningAmount + response.data.data.depositeAmount,
      );
    });
  };
  const getRuningTable = () => {
    axiosInstance.get(`/game2`).then(response => {
      if (response.data.data !== null) {
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
        );
        setRuningTableStatus(true);

        setRuningTable(sortedData);
      } else {
        setRuningTableStatus(false);
      }
    });
  };
  const getRuningTable2 = () => {
    axiosInstance.get(`/game`).then(response => {

      if (response.data.data !== null) {
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate),
        );
        setRuningTableStatus2(true);

        setRuningTable2(sortedData);
      } else {
        setRuningTableStatus2(false);
      }
    });
  };
  const getRuningGame = () => {
    axiosInstance.get(`game2/runing/${user.userId}`).then(response => {
      if (response.data.data !== null) {
        setRuning(true);
        setRuningGame([response.data.data]);
      } else {
        if (response.data.data === null) {
          axiosInstance.get(`game2/runing1/${user.userId}`).then(response1 => {
            if (response1.data.data !== null) {
              setRuning(true);
              setRuningGame([response1.data.data]);
            } else {
              if (response1.data.data === null) {
                axiosInstance
                  .get(`game2/runing2/${user.userId}`)
                  .then(response2 => {
                    if (response2.data.data !== null) {
                      setRuning(true);
                      setRuningGame([response2.data.data]);
                    } else {
                      if (response2.data.data === null) {
                        axiosInstance
                          .get(`game2/runing3/${user.userId}`)
                          .then(response3 => {
                            if (response3.data.data !== null) {
                              setRuning(true);
                              setRuningGame([response3.data.data]);
                            } else {
                              if (response3.data.data === null) {
                                setRuning(false);
                                getRuningGame();
                              }
                            }
                          });
                      }
                    }
                  });
              }
            }
          });
        }
      }
    });
  };
  const onPayment = e => {
    setAmount(e.target.value);
  };
  const onPlayGame = e => {
    if (amount > 49) {
      if (walletAmount > amount) {
        if (
          amount === '50' ||
          amount === '100' ||
          amount === '200' ||
          amount === '500' ||
          amount === '1000' ||
          amount === '5000'
        ) {
          navigate(`/Game1`, {replace: true});
        } else {
          setPlayWait(true);

          if (amount !== null && amount !== '') {
            axiosInstance.get(`game2/runing/${user.userId}`).then(response => {
              if (response.data.data !== null) {
                setRuning(true);
                setRuningGame([response.data.data]);
                message.error(
                  'You are already in the game.please complete the game first. And play again...!',
                );
                setPlayWait(false);
              } else {
                if (response.data.data === null) {
                  axiosInstance
                    .get(`game2/runing1/${user.userId}`)
                    .then(response1 => {
                      if (response1.data.data !== null) {
                        setRuning(true);
                        setRuningGame([response1.data.data]);
                        message.error(
                          'You are already in the game.please complete the game first. And play again...!',
                        );
                        setPlayWait(false);
                      } else {
                        if (response1.data.data === null) {
                          axiosInstance
                            .get(`game2/runing2/${user.userId}`)
                            .then(response2 => {
                              if (response2.data.data !== null) {
                                setRuning(true);
                                setRuningGame([response2.data.data]);
                                message.error(
                                  'You are already in the game.please complete the game first. And play again...!',
                                );
                                setPlayWait(false);
                              } else {
                                if (response2.data.data === null) {
                                  axiosInstance
                                    .get(`game2/runing3/${user.userId}`)
                                    .then(response3 => {
                                      if (response3.data.data !== null) {
                                        setRuning(true);
                                      
                                        setRuningGame([response3.data.data]);
                                        message.error(
                                          'You are already in the game.please complete the game first. And play again...!',
                                        );
                                        setPlayWait(false);
                                      } else {
                                        if (response3.data.data === null) {
                                          setRuning(false);
                                          onPlayGame2(amount);
                                        }
                                      }
                                    });
                                }
                              }
                            });
                        }
                      }
                    });
                }
              }
            });
          } else {
            alert('Please enter valid amount...!');
            message.error('Please enter valid amount...!');
          }
        }
      } else {
        message.error('Insufficient chips...!');
        navigate(`/Recharge`);
      }
    } else {
      message.error('Please enter amount 50 or above 50...!');
    }
  };

  const onPlayGame4 = (e) => {
    var ii ;
    Modal.confirm ({
      
      title: <div>
      
        <div className='mt-0'> <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
        Enter Classic v2 Room Code
        </div>
        <div class="card-body">
          <div className='pt-0'>
          <div role="alert" class="mb-2 fade text-center alert alert-primary addextra show text-dark">
              कृपया सही रूम कोड <span className='text-danger'>(ROOM CODE)</span> डाले ।
              </div>
          </div>
        {/* <label>Enter Classic v2 Room Code</label> */}
        <Input placeholder='Enter Room Code' className='shadowmyadd' type='Number' onChange={(el) => {
       ii = (el.target.value)
        }} />
        
          <div>
            <div className='pt-2 m-0'>
             
              <div role="alert" class="m-0  fade alert alert-primary addextra show text-dark">
              NOTE:- यदि आपके <span className='text-danger'>ROOM CODE</span> गलत पाया जाता है तो आपका गेम हारा हुआ माना जाएगा ।

             <p className='pt-1 m-0'> टेबल लगने के बाद आप <span className='text-danger'>ROOM CODE</span> चेंज नहीं कर सकते ।</p>
              </div>
             
            
            </div>
          </div>
        </div>
      </div></div>
      </div>,
      okText: "Yes",
      okType: "succes",
      cancelText: "No",
      onOk() {

      },
      CancelText: "Yes",
      CancelType: "Danger",
      cancelText: "No",
      onCancel() {},
    });
  };
  const onPlayGame1 = () => {
    var ii ;
    Modal.confirm ({
      
      title: <div>
      
        <div className='mt-0'> <div className="shadow card">
        <div className="bg-light text-dark text-capitalize card-header">
        Enter Classic v2 Room Code
        </div>
        <div class="card-body">
          <div className='pt-0'>
          <div role="alert" class="mb-2 fade text-center alert alert-primary addextra show text-dark">
              कृपया सही रूम कोड <span className='text-danger'>(ROOM CODE)</span> डाले ।
              </div>
          </div>
        {/* <label>Enter Classic v2 Room Code</label> */}
        <Input placeholder='Enter Room Code' className='shadowmyadd' type='Number' onChange={(el) => {
       ii = (el.target.value)
        }} />
        
          <div>
            <div className='pt-2 m-0'>
             
              <div role="alert" class="m-0  fade alert alert-primary addextra show text-dark">
              NOTE:- यदि आपके <span className='text-danger'>ROOM CODE</span> गलत पाया जाता है तो आपका गेम हारा हुआ माना जाएगा ।

             <p className='pt-1 m-0'> टेबल लगने के बाद आप <span className='text-danger'>ROOM CODE</span> चेंज नहीं कर सकते ।</p>
              </div>
             
            
            </div>
          </div>
        </div>
      </div></div>
      </div>,
      okText: "Yes",
      okType: "succes",
      cancelText: "No",
      onOk() {
        if(ii === undefined) {
          setPlayWait(false);
          message.error("please provide a valid ROOM CODE")
        }
        else{
          const data = {
            gameDate: new Date(),
            player1: user.userId,
            status: 'created',
            gameAmount: amount,
            playerCount: 1,
            winningAmount: amount * 2 - amount * 0.1,
            roomCode: ii,
            debitAmount: false,
          };
          if (
            data.player1 !== undefined &&
            data.gameAmount !== undefined &&
            data.winningAmount !== undefined &&
            data.status !== undefined &&
            data.roomCode !== undefined &&
            data.roomCode !== '' &&
            data.status !== '' &&
            data.winningAmount !== '' &&
            data.gameAmount !== '' &&
            data.player1 !== ''
          ) {
            axiosInstance.post('/game2', data).then(res => {
              if (res.data && res.data.responseCode === -1) {
                message.error('Record Already Exists');
              } else if (res.data && res.data.responseCode === 1) {
                navigate(
                  `/GameRoom2/${encodeURIComponent(JSON.stringify(res.data.data))}`,
                  {replace: true},
                );
              } else message.error('Something wrong. Please try again...!');
            });
          } else {
            message.error(
              'Please fill out all required fields. And submit again...!',
            );
          }
        }
    
      },
      CancelText: "Yes",
      CancelType: "Danger",
      cancelText: "No",
      onCancel() {
        setPlayWait(false);

      },
    });
  };

  const onPlayGame2 = e => {
    if (runing === false) {
      if(walletAmount > amount){

      axiosInstance.post('/game2/user/status', {gameAmount: e}).then(res => {
        if (res.data.data === null) {
          onPlayGame1(e);
        } else {
          axiosInstance
            .put(`/game2/${res.data.data._id}`, {
              status: 'joined',
              playerCount: 2,
              player2: user.userId,
            gameJoinedDate:new Date()

            })
            .then(response => {
              if (response.data.responseCode === 1) {
                axiosInstance.get(`/game2/${res.data.data._id}`).then(res => {
                  navigate(
                    `/GameRoom2/${encodeURIComponent(
                      JSON.stringify(res.data.data),
                    )}`,
                    {replace: true},
                  );
                });
              }
            });
        }
      });
    }
    else {
      message.error("Insufficient chips...!");
      navigate(`/Recharge`);
  
    }
       
    } else {
      alert('You are already in a game...!');
      message.error('You are already in a game...!');
    }
  
  };

  const onViewGame = e => {
    navigate(
      `/GameRoom2/${encodeURIComponent(JSON.stringify(runingGame[0]))}`,
      {replace: true},
    );
  };


  const onPlayGame11 = e => {
    if (walletAmount > e) {
      console.log("eeeeeeeeee",e)
      setPlayWait11(e);
      axiosInstance.get(`game/runing/${user.userId}`).then(response => {
        if (response.data.data !== null) {
          setRuning(true);
          setRuningGame([response.data.data]);
          message.error(
            'You are already in the game.please complete the game first. And play again...!',
          );
          setPlayWait11(false);
        } else {
          if (response.data.data === null) {
            axiosInstance.get(`game/runing1/${user.userId}`).then(response1 => {
              if (response1.data.data !== null) {
                setRuning(true);
                setRuningGame([response1.data.data]);
                message.error(
                  'You are already in the game.please complete the game first. And play again...!',
                );
          setPlayWait11(false)

              } else {
                if (response1.data.data === null) {
                  axiosInstance
                    .get(`game/runing2/${user.userId}`)
                    .then(response2 => {
                      if (response2.data.data !== null) {
                        setRuning(true);
                        setRuningGame([response2.data.data]);
                        message.error(
                          'You are already in the game.please complete the game first. And play again...!',
                        );
          setPlayWait11(false)

                      } else {
                        if (response2.data.data === null) {
                          axiosInstance
                            .get(`game/runing3/${user.userId}`)
                            .then(response3 => {
                              if (response3.data.data !== null) {
                                setRuning(true);
                                setRuningGame([response3.data.data]);
                                message.error(
                                  'You are already in the game.please complete the game first. And play again...!',
                                );
          setPlayWait11(false)

                              } else {
                                if (response3.data.data === null) {
                                  setRuning(false);
                                  onPlayGame112(e);
                                }
                              }
                            });
                        }
                      }
                    });
                }
              }
            });
          }
        }

        //setWalletAmount(response.data.data.amount);
      });
    } else {
      message.error('Insufficient chips...!');
      navigate(`/Recharge`);
    }
  };

  const onPlayGame112 = e => {
    // if(walletAmount>e){

    axiosInstance.post('/game/user/status', {gameAmount: e}).then(res => {
      if (res.data.data === null) {
        onPlayGame1(e);
      } else {
        axiosInstance
          .put(`/game/${res.data.data._id}`, {
            status: 'joined',
            playerCount: 2,
            player2: user.userId,
            gameJoinedDate:new Date()
          })
          .then(response => {
            if (response.data.responseCode === 1) {
              message.success('Table created successfully');
              axiosInstance.get(`/game/${res.data.data._id}`).then(response => {
                navigate(
                  `/GameRoom/${encodeURIComponent(
                    JSON.stringify(response.data.data),
                  )}`,
                  {replace: true},
                );
              });
            }
          });
      }
    });
    //   }
    // else {
    //   message.error("Insufficient chips...!");
    //   navigate(`/Recharge`);

    // }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    const scrollY = window.scrollY;
    setIsVisible(scrollY > 100); // Change the threshold as needed
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="realludokingsize bgclr">
         <div className=" d-flex align-items-center justify-content-between mt-3">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{walletAmount}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
       
      </div>
      <div class="d-flex flex-column">
        <div class="bg-gray-200 h-100 w-100 p-3  d-flex align-items-center justify-content-between hstack gap-2">
          <div class="input-group flex-1 flex-nowrap">
            <input
              type="tel"
              class="form-control"
              id="inputSetChallenge"
              required=""
              name="amount"
              min="50"
              max="15000"
              placeholder="Amount"
              onChange={onPayment}
            />
            {!playWait && (
              <button
                type="button"
                class="btn btn-primary w-25"
                id="setBt"
                onClick={() => onPlayGame()}>
                Set
              </button>
            )}
            {playWait && (
              <button
              type="button"
              class="btn btn-primary w-25"
              id="setBt"
             >
             <div>
                <div class="spinner-border text-white" role="status"></div>
              </div>
            </button>
              
            )}
          </div>
        </div>
        <ul id="playable-challange-list" class="m-0 px-2">
          


{runing && (<li class="p-0  appear-from-left ">
            <div className=' p-agggg'>
              <div className="my-2 card02 ">
              <div className='loader02'>
            <div className='wrapper'>
            <div className="d-flex align-items-center justify-content-center pt-2">
             
               
                
                 
             </div>
             <div className="d-flex align-items-center justify-content-between card-body myadd">
             
             <div className="d-flex align-items-center flex-grow-1">
{/*              
          <h6 className="fw-semibold  text-white m-0 mt-2"
                 style={{ fontSize: '100%'}}>Prize pool</h6> */}
                 <img src={prizepool}   width={40} alt="" />
             
             </div>
             <div>
               
             </div>
             <div className="d-flex align-items-center">
             <h6 className="fw-semibold  text-white m-0 mt-2"
                 style={{ fontSize: '100%'}}>Running</h6>

             </div>
           </div>
             <div className="d-flex align-items-center justify-content-between card-body pt-0">
             
               <div className="d-flex align-items-center flex-grow-1">
            
                 <span
                   className="fw-semibold text-truncate text-white mt-1"
                   style={{ fontSize: '120%',marginLeft:'5px'}} >
                   &#8377;{runingGame[0].winningAmount}
                 </span>
               </div>
               
               <div className="d-flex align-items-center">
                 <div className="hstack gap-2">
                   {playWait !== "50" &&<button className="btn btn-primary playChallange btn-sm mycoustm fw-bold " onClick={() => onViewGame('50')}>View </button> }
                   {playWait==="50" &&  <div class="spinner-border text-success" role="status">

</div>}
                 </div>
               </div>
             </div>
             <div>
             <div className="d-flex align-items-center justify-content-between content03"style={{marginTop:'-90px'}}>
               <div style={{width:"20%"}}>
           
               </div>
               <div style={{width:"60%",textAlign:'center'}}>
                 <img className="mainaadmi" src={desiaadmi} style={{width:'50%'}} alt="" />
               </div>
               <div style={{width:"20%",textAlign:'end'}}>
              
               </div>
               
               
             </div>
             </div>
             <div class=" text-muted d-flex bgtable align-items-center  justify-content-center pb-3">
             <div className='d-flex bgtable align-items-center  text-white buttonft02  justify-content-center ' style={{width:'320px'}}> 
              
             <span className="d-flex align-items-center">
               <img className="wifiji me-2" src={wifi02} alt="" />
                <span className=' text-white'> Currently Running</span> 
                <img className="wifiji ms-2" src={wifi02} alt="" />

               </span>

               </div>
</div>
            </div>
            </div>
     
                
              </div>
              </div>
             
            </li>)}


          


{runingTableStatus && (
            <div>
              {runingTable2 &&
                runingTable[0] &&
                runingTable.map(el => (
                  <li className="p-0  appear-from-left">
                       <div className=' p-agggg'>
                       <div className="my-2 card02">
                      <div className="d-flex align-items-center justify-content-between card-body myadd text-white pt-3">
                        <span>Challenge set by</span>
                        <span className="text-white fw-bold">
                          Rs {el.gameAmount}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between card-body">
                        <div className="d-flex align-items-center justify-content-between ">
                          <div
                            className="bg-dark rounded-circle me-2"
                            style={{height: '24px', width: '24px'}}>
                            <img src={ludo} alt="avatar" />
                          </div>
                          <span
                            className="fw-semibold text-white text-start">
                            {el.player1.name}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="hstack gap-2">
                          {  playWait11!==`${el.gameAmount}` && <button
                              className="btn btn-success playChallange btn-sm"
                              onClick={() => onPlayGame2(`${el.gameAmount}`)}>
                              Play
                            </button>}
                            {playWait11===`${el.gameAmount}` && 
              <button
              type="button"
              class="btn btn-primary "
              id="setBt"
             >
             <div>
                <div class="spinner-border text-white" role="status"></div>
              </div>
            </button>
              
           }
                          </div>
                        </div>
  
                      </div>
                      <div class=" text-muted d-flex bgtable align-items-center  justify-content-center pb-3">
            
</div>
                    </div>
                       </div>
                   
                  </li>
                ))}
            </div>
          )}







          {runingTableStatus2 && (
            <div>
              {runingTable2 &&
                runingTable2[0] &&
                runingTable2.map(el => (
                  <li className="p-0  appear-from-left">
                       <div className=' p-agggg'>
                       <div className="my-2 card02">
                      <div className="d-flex align-items-center justify-content-between card-body myadd text-white pt-3">
                        <span>Challenge set by</span>
                        <span className="text-white fw-bold">
                          Rs {el.gameAmount}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between card-body">
                        <div className="d-flex align-items-center justify-content-between ">
                          <div
                            className="bg-dark rounded-circle me-2"
                            style={{height: '24px', width: '24px'}}>
                            <img src={ludo} alt="avatar" />
                          </div>
                          <span
                            className="fw-semibold text-white text-start">
                            {el.player1.name}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="hstack gap-2">
                          {  playWait11!==`${el.gameAmount}` && <button
                              className="btn btn-success playChallange btn-sm"
                              onClick={() => onPlayGame11(`${el.gameAmount}`)}>
                              Play
                            </button>}
                            {playWait11===`${el.gameAmount}` && 
              <button
              type="button"
              class="btn btn-primary "
              id="setBt"
             >
             <div>
                <div class="spinner-border text-white" role="status"></div>
              </div>
            </button>
              
           }
                          </div>
                        </div>
  
                      </div>
                      <div class=" text-muted d-flex bgtable align-items-center  justify-content-center pb-3">
            
</div>
                    </div>
                       </div>
                   
                  </li>
                ))}
            </div>
          )}
        </ul>
        <div className='card-body pt-0 pb-0  '>
        <marquee className='card-body myjikj adcj' >
          <div className='d-flex'>
            
        <h2 className=' kl02 m-0 text-white'>Completed Tables &#129351;...</h2>
        <h2 className=' kl02 m-0 text-warning'>Completed Tables &#128142;...</h2>
          </div>

</marquee> 
        </div>
        
        <ul id="playable-challange-list" class="m-0 px-2">
         
 <li className="p-0  appear-from-left">

            <div className=' p-agggg'>
           
            <div className="my-2 card02 ">
         
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-white"
                      style={{width: '80px'}}>
                      Byan
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="40" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-white"
                      style={{width: '80px'}}>
                      Raju Kako
                    </span>
                  </div>
                </div>
                <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
                <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
                <div className="d-flex align-items-center justify-content-center">
                  <span className="text-white fw-bold">Rs 2300</span>
                </div>
                  

                  </div>
  </div>
               
              </div>
          
            </div>
          </div>
          </li>
          <li className="p-0  appear-from-left">

            <div className=' p-agggg'>
           
            <div className="my-2 card02 ">
         
              <div className="text-start card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div
                      className="bg-dark rounded-circle me-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-white"
                      style={{width: '80px'}}>
                      Byan
                    </span>
                  </div>
                  <div>
                    <img src={vs} height="40" alt="vs" />
                  </div>
                  <div className="d-flex flex-row-reverse align-items-center">
                    <div
                      className="bg-dark rounded-circle ms-2"
                      style={{height: '24px', width: '24px'}}>
                      <img src={ludo} alt="avatar" />
                    </div>
                    <span
                      className="fw-semibold text-white"
                      style={{width: '80px'}}>
                      Raju Kako
                    </span>
                  </div>
                </div>
                <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
                <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
                <div className="d-flex align-items-center justify-content-center">
                  <span className="text-white fw-bold">Rs 2300</span>
                </div>
                  

                  </div>
  </div>
               
              </div>
          
            </div>
          </div>
          </li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li> <li className="p-0  appear-from-left">

<div className=' p-agggg'>

<div className="my-2 card02 ">

  <div className="text-start card-body">
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <div
          className="bg-dark rounded-circle me-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Byan
        </span>
      </div>
      <div>
        <img src={vs} height="40" alt="vs" />
      </div>
      <div className="d-flex flex-row-reverse align-items-center">
        <div
          className="bg-dark rounded-circle ms-2"
          style={{height: '24px', width: '24px'}}>
          <img src={ludo} alt="avatar" />
        </div>
        <span
          className="fw-semibold text-white"
          style={{width: '80px'}}>
          Raju Kako
        </span>
      </div>
    </div>
    <div class=" text-muted d-flex bgtable align-items-center pt-2 justify-content-center ">
    <div className='d-flex bgtable align-items-center   text-white buttonft  justify-content-center ' style={{width:'320px'}}> 
    <div className="d-flex align-items-center justify-content-center">
      <span className="text-white fw-bold">Rs 2300</span>
    </div>
      

      </div>
</div>
   
  </div>

</div>
</div>
</li>
         
         
        </ul>
      </div>
      <button class={`Btn02 ${isVisible ? 'visible' : ''} `} id='Btn02' onClick={()=>scrollToTop()}>
  <svg height="1.2em" class="arrow" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"></path></svg>

</button>
    </div>
  );
};

export default Game2;
