

import React, { useContext, useEffect, useState,useRef } from "react";
import {Input, message, Table, Modal,Select, Space } from "antd";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import axiosInstance from "../../axiosInstance";
import trns from "../../static/media/Add-a-heading-1-min.png";
import moment from "moment";
import back from "../../images/ludo/back01.png";

import hestoryicon from '../../images/ludo/joinvs.png'
import home from '../../images/ludo/kkkkk.png'
import profile02 from '../../images/ludo/prfils.png'
import rewards from '../../images/ludo/rewardlast.png'
import walletman from '../../images/ludo/invant.png'

import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import women from '../../images/ludo/woman.png';
import click from '../../static/media/music/click.mp3'

const History = (props) => {


  const user = useContext(UserContext);
  let navigate = useNavigate();
  const [PaymentData1, setPaymentData1] = useState([]);
  const [PaymentData2, setPaymentData2] = useState([]);
  const [gameData, setGameData] = useState(null);
  const [ShowT, setShowT] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
const [walletAmount , setWalletAmount] = useState(null)

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, pickupService) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(pickupService);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const onRedirect = (e) => {
    audioRef.current.play();
    navigate(`/${e}`)
   };

  
////for tap voice/////
const audioRef = useRef(null);


  const getColumnSearchProps = (pickupService) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${pickupService}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys, confirm, pickupService)
          }
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, pickupService)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(pickupService);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[pickupService]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === pickupService ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
      
  });
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    getWPayment();
    getRPayment();
    getGame();
    getWallet();
  }
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then((res) => {
        setWalletAmount(res.data.data.depositeAmount+res.data.data.winningAmount)
    });
  }


  const getRPayment = () => {
    axiosInstance.get(`/payment/user/${user.userId}`).then((response) => {
      console.log("payment",response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setPaymentData2(sortedData);
    });
  };
  const getWPayment = () => {
    axiosInstance.get(`/withdraw/user/${user.userId}`).then((response) => {
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.transactionDate) - new Date(a.transactionDate)
      );
      setPaymentData1(sortedData);
      console.log("data",sortedData)
    });
  };
var gameC = []
var getG = 1 
  const getGame = () =>{
    getG = getG +1
   if(getG === 2) {
    axiosInstance.get(`/game/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      console.log("length",response.data.data.length)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame2()
    }
    else{
      getGame3();
     }
    });
    
   }
  }
var getG2 = 1 

  const getGame2 = () =>{
    getG2 = getG2 +1
   if(getG2 === 2) {
    axiosInstance.get(`/game/runing5/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
      getGame3();
      }
      else{
        getGame3();
       }
    });

   }
  }
  var getG3 = 1 

  const getGame3 = () =>{
    getG3 = getG3 +1
   if(getG3 === 2) {
    axiosInstance.get(`/game2/runing4/${user.userId}`).then((response) => {
      if(response.data.data !== null && response.data.data !== undefined){
        const sortedData = [...response.data.data].sort(
          (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
        );
        gameC.push(...sortedData)
        getGame4();
      }
     else{
      getGame4();
     }

    });
    
   }
  }
  var getG4 = 1 

  const getGame4 = () =>{
    getG4 = getG4 +1
   if(getG4 === 2) {
    axiosInstance.get(`/game2/runing5/${user.userId}`).then((response) => {
      console.log(response.data.data)
      const sortedData = [...response.data.data].sort(
        (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
      );
      gameC.push(...sortedData)
    console.log("gameC",gameC)

    });
    const sortedData = [...gameC].sort(
      (a, b) => new Date(b.gameDate) - new Date(a.gameDate)
    );
    setGameData(sortedData)
   }
  }
  const columns3 = [
   
      
     
      {
        title: "Game Date",
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.gameDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
      {
        title: "Room Code",
        dataIndex: "roomCode",
      key: "roomCode",
      ...getColumnSearchProps("roomCode")
      },
      {
        title: "Table Amount",
        dataIndex: "gameAmount",
        key: "gameAmount",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
   
  ];
  const columns1 = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //     title: "Name",
    //     render: (row) => {
    //         return row.member ? (
    //           <div>
    
    //           { row&& <b>{row.member.name}</b>}<br/>
            
    //             </div>
    //         ) : (
    //           <div> </div>
    //         );
    //       },
    //   },
      {
        title: "UPI ID",
        dataIndex: "upi",
      key: "upi",
      },
     
      {
        title: "Transaction Date",
        
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
   
  ];
  const columns2 = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //     title: "Name",
    //     render: (row) => {
    //         return row.member ? (
    //           <div>
    
    //           { row&& <b>{row.member.name}</b>}<br/>
            
    //             </div>
    //         ) : (
    //           <div> </div>
    //         );
    //       },
    //   },
      // {
      //   title: "UPI ID",
      //   render: (row) => {
      //       return row.upiId ? (
      //         <div>
    
      //         { row&& <b>{row.upiId.name}</b>}<br/>
            
      //           </div>
      //       ) : (
      //         <div> </div>
      //       );
      //     },
      // },
     
      {
        title: "Transaction Date",
        
        render: (row) => {
            return(
              <>
              {row && <div>{moment(row.transactionDate).format("DD-MM-yyyy")}</div>}
              </>
            );
          },
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "UTR Number",
        dataIndex: "utr",
        key: "utr",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
    
  ];
  const onHistory = (e) =>{
if(e==="Withdraw"){
  setShowT(1)
}
else{
  if(e==="Recharge"){
    setShowT(2)
  }
  else{
    setShowT(3)
  }
}
  }


  const [startX, setStartX] = useState(null);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };
  
  
const handleTouchMove = (e) => {
  if (!startX) return;
  const currentX = e.touches[0].clientX;
  const difference = startX - currentX;
  if (Math.abs(difference) > 50) {
    if (difference > 0) {
      navigate('/History')
      console.log('Swipe left');
    } else {
      navigate('/HomeScreen')
      console.log('Swipe right');
    }
    setStartX(null);
  }
};



  return (

    <div className=" realludokingsize bgclr " onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={() => setStartX(null)}
    style={{ width: '100%', height: '100vh', backgroundColor: '#f0f0f0',paddingBottom:'100%' }}>
     <div className='p-3 pe-0'>
     <div className="w-100 d-flex align-items-center justify-content-between">
          <a href='#/HomeScreen' className=''>
            <button className="btn m-0 p-0 ">
              <span className="text-capitalize"><img className='backimg' src={back} alt="" /></span>
            </button>
          </a>
          <div className="text-center mainwelcome d-flex align-items-center justify-content-between" >
             <div className="bgredg0203  myadd d-flex  p-1 align-items-center justify-content-between">
            <div>
            <img src={women} className="hello" alt="" /><span>{user.userName.substring(0, 10)}...
               </span>
            </div>
            <div >
              <h6 className="p-1 rajauto" style={{marginBottom:'0px',marginRight:'1px',background:'yellow',borderRadius:'20px',}}><span style={{padding:"5px",fontSize:"14px"}}>&#x20B9;{walletAmount}</span></h6>
            </div>
             </div>
           
              </div>
        </div>
     </div>
      
    <div style={{marginBottom:'450px'}}>
<Container>

<div className="d-flex align-items-center justify-content-evenly overflow-auto pt-3 px-0 container">
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Withdraw")}>Withdraw</Button>
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Recharge")}>Recharge</Button>
      <Button className="text-capitalize me-2 py-2 px-4 border text-dark badge rounded-pill text-white bg-primary" style={{ cursor: 'pointer' }} onClick={()=>onHistory("Game")}>Game</Button>
    </div>
{ShowT === 2 && <form className="d-flex ms-auto" >
<input
  type="text"
  className="form-control me-2 mb-2"
  placeholder="Enter UTR number"
  pattern="^\d{8}|\d{12}$"
  required
  minLength={8}
  maxLength={12}
  value=""
/>
<button className="btn btn-primary mb-2" type="submit" disabled>
  Search
</button>
</form>}
{/* {ShowT === 3 && <form className="d-flex ms-auto" >
<input
  type="text"
  className="form-control me-2 mb-2"
  placeholder="Enter Room code "
  pattern="^\d{8}|\d{12}$"
  required
  minLength={8}
  maxLength={12}
  value=""
/>
<button className="btn btn-primary mb-2" type="submit" disabled>
  Search
</button>
</form>} */}
{ShowT===3 && gameData === null && <div classname>
<div>
<img src={trns} alt="no history" className="w-100" />
</div>
</div> }
 <div className="master-snf-screen container">
      <div>
       {ShowT===1 && <Table columns={columns1} dataSource={PaymentData1} />}
        {ShowT===2 && <Table columns={columns2} dataSource={PaymentData2} />}
        {ShowT===3 && <Table columns={columns3} dataSource={gameData} />}
      
      </div>
      
    </div>
</Container>
<div class="button-container">
    <audio ref={audioRef} src={click} />
      <button onClick={()=>onRedirect("wallet")}  class="button02 addleft">
      <img style={{width:"45px"}}  className="home01"  src={rewards} alt="" />
     
      </button>
   
      <button onClick={()=>onRedirect("profile")}  class="button02" >
      <img style={{width:"36px"}} className="home01"   src={profile02} alt="" />
      </button>
      <button onClick={()=>onRedirect("homescreen")}  class="button02">
      <img style={{width:"45px"}} className="home01 homeeee"  src={home} alt="" />
      </button>
      <button onClick={()=>onRedirect("wallet")}  class="button02">
      <img style={{width:"40px"}}  src={walletman} className="home01" alt="" />
      </button>
      <button onClick={()=>onRedirect("history")}  class="button02 addright">
      <img style={{width:"45px"}}  className="home01"  src={hestoryicon} alt="" />

      </button>
    </div>
</div>
    </div>
  );
};

export default History;